import {Component, OnInit} from '@angular/core';
import {StatisticService} from './statistic.service';
import {darkblue} from 'color-name';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  bounds = {
    east: 30,
    west: 60,
    south: 30,
    north: 60,
  };

  constructor(
    private statService: StatisticService,
    private sharedService: SharedService
  ) {
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    animation: {
      duration: 0
    },
    scales: {
      xAxes: [{
        display: false,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        display: false,
        gridLines: {
          display: false
        }
      }]
    }
  };
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartData = [
  ];

  ngOnInit() {
    this.sharedService.currentMapBounds.subscribe((message) => {
      this.bounds = message;

      this.statService.getStatistic(
        this.bounds.east,
        this.bounds.west,
        this.bounds.south,
        this.bounds.north
      ).subscribe((resp) => {
        this.barChartLabels = [];
        this.barChartData = [
          {data: [], label: 'Series A', backgroundColor: 'green'}
        ];

        resp.forEach(d => {
          this.barChartLabels.push(d.date);
          this.barChartData[0].data.push(d.qty);
        });

      });
    });
  }
}
