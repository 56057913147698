import {AfterViewInit, Component} from '@angular/core';
import * as L from 'leaflet';
import {HttpClient} from '@angular/common/http';
import {ObjectsService} from './objects.service';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit {
  private map;
  private pointsLayer;
  private carsLayer;
  public run = 164516;
  private signal;
  private carsSignal;

  constructor(
    private http: HttpClient,
    private objectService: ObjectsService,
    private sharedService: SharedService,
  ) {
  }

  private getRoutes() {
    return this.http.get('http://165.22.66.239:8000/routes/?run=' + this.run);
  }


  public renderRoutes() {
    function getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const routes = {};
    this.getRoutes().subscribe((data: Array<{ lat: string, lon: string, run: number }>) => {
      data.forEach(d => {
        routes[d.run] = routes[d.run] || [];
        routes[d.run].push([d.lat, d.lon]);
      });

      for (const k in routes) {
        const line = L.polyline(routes[k], {color: getRandomColor(), weight: 5});
        line.addTo(this.map);
      }
    });

  }

  public renderData() {

    const iconPoint = L.icon({
      iconUrl: '/assets/bucket_full.png',
      iconSize: [35, 37],
      iconAnchor: [32, 77],
      popupAnchor: [-3, -35]
    });

    const iconPointEmpty = L.icon({
      iconUrl: '/assets/bucket_empty.png',
      iconSize: [35, 37],
      iconAnchor: [0, 0],
      popupAnchor: [-3, -76]
    });

    const iconCar = L.icon({
      iconUrl: '/assets/truck.png',
      iconSize: [35, 37],
      iconAnchor: [0, 0],
      popupAnchor: [-3, -76]
    });

    const iconCluster = L.divIcon({
      className: 'map-marker',
      iconSize: null,
      html: '<div>' +
        '<span class="cluster-label"><span class="cluster-label--in">0</span></span>' +
        '<svg class="circle-chart" viewbox="0 0 33.83098862 33.83098862" width="50" height="50" xmlns="http://www.w3.org/2000/svg">' +
        '<circle class="circle-chart__background" stroke="#FF0000" stroke-width="2" fill="none" cx="16.91549431" cy="16.91549431" r="13.91549431" />' +
        '<circle class="circle-chart__circle" stroke="" stroke-width="5" stroke-dasharray="35,100" fill="none" cx="16.91549431" cy="16.91549431" r="13.91549431" />' +
        '</svg></div>'
    });

    const clickZoom = (e) => {
      this.map.setView(e.target.getLatLng(), this.map.getZoom() < 19 ? this.map.getZoom() + 2 : 20);
    };

    this.sharedService.changeMapBounds(
      this.map.getBounds().getEast(),
      this.map.getBounds().getWest(),
      this.map.getBounds().getNorth(),
      this.map.getBounds().getSouth()
    );

    if (this.signal && this.signal.unsubscribe) {
      this.signal.unsubscribe();
    }

    this.signal = this.objectService.getClusters(
      this.map.getBounds().getEast(),
      this.map.getBounds().getWest(),
      this.map.getBounds().getNorth(),
      this.map.getBounds().getSouth(),
      this.map.getZoom()
    );

    // this.carsSignal = this.objectService.getCars(
    //   this.map.getBounds().getEast(),
    //   this.map.getBounds().getWest(),
    //   this.map.getBounds().getNorth(),
    //   this.map.getBounds().getSouth()
    // );

    // this.carsSignal.subscribe((data) => {
    //   if (this.carsLayer) {
    //     this.carsLayer.clearLayers();
    //   }
    //
    //   data.forEach((d) => {
    //     const marker = L.marker([d.lat, d.lon],
    //       {icon: iconCar});
    //     marker.addTo(this.carsLayer);
    //   });
    // });

    this.signal.subscribe((data) => {
      if (this.pointsLayer) {
        this.pointsLayer.clearLayers();
      }

      data.forEach((d) => {
        const bIsPoint = !d.destination_quantity;
        const marker = L.marker([d.lat, d.lon],
          {icon: bIsPoint ? (d.visited ? iconPointEmpty : iconPoint) : iconCluster});

        marker.addTo(this.pointsLayer);


        const span = marker._icon.getElementsByClassName('cluster-label--in')[0];
        const circle = marker._icon.getElementsByClassName('circle-chart__circle')[0];

        if (span) {
          span.innerHTML = d.destination_quantity;

          if (d.destination_quantity > 1000) {
            span.style.fontSize = '11px';
          }
        }

        if (circle) {
          circle.setAttribute(
            'stroke-dasharray',
            Math.round(
              d.visit_amount * 100 / d.destination_quantity
            ) + ',100'
          );
        }

        marker.on('click', clickZoom);

      });
    });
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [38.67342, 60.349439],
      zoom: 4
    });

    this.pointsLayer = L.layerGroup().addTo(this.map);
    this.carsLayer = L.layerGroup().addTo(this.map);


    this.map.on('moveend', () => {
      this.renderData();
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.renderRoutes();

    this.renderData();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }
}
