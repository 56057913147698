import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ClusterModel} from '../models/cluster.model';
import {Observable} from 'rxjs';
import {CarModel} from '../models/car.model';

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {

  constructor(private http: HttpClient) {
  }

  getClusters(east: number, west: number, north: number, south: number, zoom: number): Observable<ClusterModel[]> {

    let params = new HttpParams();

    params = params
      .append('east', east.toString())
      .append('west', west.toString())
      .append('north', north.toString())
      .append('south', south.toString())
      .append('zoom', zoom.toString());


    return this.http.get<ClusterModel[]>(environment.apiUrl + (zoom > 14 ? '/objects/' : '/clusters/'), {params});
  }

  getCars(east: number, west: number, north: number, south: number): Observable<CarModel[]> {

    let params = new HttpParams();

    params = params
      .append('east', east.toString())
      .append('west', west.toString())
      .append('north', north.toString())
      .append('south', south.toString());


    return this.http.get<CarModel[]>(environment.apiUrl + '/cars/', { params });
  }

  setBounds(east, west, north, south) {

  }
}
