<div class="timeline" *ngIf="barChartData[0]" >
  <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
  >
  </canvas>
</div>
