import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {StatisticModel} from '../models/date.statistic.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {
  constructor(private http: HttpClient) { }

  getStatistic(east: number, west: number, north: number, south: number): Observable<StatisticModel[]> {

    let params = new HttpParams();
    params = params
      .append('east', east.toString())
      .append('west', west.toString())
      .append('north', north.toString())
      .append('south', south.toString());

    return this.http.get<StatisticModel[]>(environment.apiUrl + '/statistic/', {params});
  }
}
