import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private messageSource = new BehaviorSubject({
    east: 30,
    west: 60,
    south: 30,
    north: 60,
  });
  currentMapBounds = this.messageSource.asObservable();

  changeMapBounds(
    east: number,
    west: number,
    south: number,
    north: number) {
    this.messageSource.next({
      east,
      west,
      south,
      north
    });
  }
}
